import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';

import { useSpring, a, to, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import * as fromUserInterface from 'store/UserInterface';
import * as fromInterfaceOverlay from 'store/InterfaceOverlay';
import * as fromMainNavigation from 'store/MainNavigation';

import { useTimeout, useLatest } from 'utils/Index';

export type IBackgroundShade = 'light' | 'dark';

export interface IProps {
  backgroundShade: IBackgroundShade;
  mainSiteOverlay: boolean;
  updateWindowDimensions(): void;
}

const ModalTriggerComponent: React.FC<IProps> = ({
  mainSiteOverlay,
  backgroundShade,
  updateWindowDimensions,
}) => {

  const animateContentIn = useSelector(fromUserInterface.getPageContentAnimatedIn);
  const navMenuOpen = useSelector(fromMainNavigation.getNavMenuOpen);
  const active = useSelector(fromInterfaceOverlay.getChatModalTrigger);
  const open = useSelector(fromInterfaceOverlay.getChatModalWindow);
  const chatStarted = useSelector(fromInterfaceOverlay.getChatModalStarted);
  const notificationDot = useSelector(fromInterfaceOverlay.getChatModalNotificationDot);
  const triggerWidth = useSelector(fromInterfaceOverlay.getChatTriggerWidth);

  const dispatch = useDispatch();

  const enterContactTriggerAction = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.enterContactTriggerAction())
    , [dispatch]);

  const leaveContactTriggerAction = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.leaveContactTriggerAction())
    , [dispatch]);

  const openChatModalAction = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.openChatModalAction())
    , [dispatch]);

  const closeChatModalAction = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.closeChatModalAction())
    , [dispatch]);

  const [animateIn, setAnimateIn] = React.useState(false);
  const [hovering, setHovering] = React.useState(false);

  const [delay, setDelay] = React.useState(2000);
  const [initialised, setInitialised] = React.useState(false);
  const [triggerTimeout, setTriggerTimeout] = React.useState(false);

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 400,
  };

  const [openHoverSpring, setOpenHoverSpring] = useSpring(() => ({ active: 0, config: springConfig }));
  const [triggerHeightSpring, setTriggerHeightSpring] = useSpring(() => ({ x: 0, config: springConfig }));
  const [triggerWidthSpring, setTriggerWidthSpring] = useSpring(() => ({ x: 14, config: springConfig }));
  const [closeHoverSpring, setCloseHoverSpring] = useSpring(() => ({ active: 0, config: springConfig }));
  const [windowSpring, setWindowSpring] = useSpring(() => ({ x: 0, config: springConfig }));
  const [notificationSpring, setNotificationSpring] = useSpring(() => ({ x: 0, config: springConfig }));

  const [chatIconSpring, setChatIconSpring] = useSpring(() => ({ x: 0, config: springConfig }));

  React.useEffect(() => {
    setWindowSpring({ x: open ? 1 : 0 });
  }, [open]);

  React.useEffect(() => {
    setOpenHoverSpring({ active: active ? 1 : 0 });
    setTriggerHeightSpring({ x: (active || chatStarted && !open) ? 1 : 0 });
    setTriggerWidthSpring({ x: chatStarted ? triggerWidth : 14 });
  }, [active, triggerWidth, chatStarted, open]);

  React.useEffect(() => {
    setChatIconSpring({ x: !mainSiteOverlay && chatStarted && !open ? 1 : 0 });
  }, [mainSiteOverlay, chatStarted, open]);

  React.useEffect(() => {
    setNotificationSpring({ x: ((mainSiteOverlay && active) || (!mainSiteOverlay && !active)) && chatStarted && notificationDot && !open ? 1 : 0 });
  }, [mainSiteOverlay, chatStarted, active, notificationDot, open]);

  const [animateInSpring, setAnimateInSpring] = useSpring(() => ({ active: 0, config: springConfig }));

  React.useEffect(() => {
    setAnimateInSpring({ active: animateIn ? 1 : 0 });
  }, [animateIn]);

  const latestInitialised = useLatest(initialised);

  useTimeout(() => {
    if (!latestInitialised.current) {
      handleTriggerMouseLeave();
      setInitialised(true);
    }
    setTriggerTimeout(false);
  }, triggerTimeout ? delay : null);

  React.useEffect(() => {
    if (animateContentIn && !animateIn) {
      setAnimateIn(true);
      if (!initialised && mainSiteOverlay) {
        showTrigger();
        updateWindowDimensions();
        setTriggerTimeout(true);
      }
    }
  }, [animateContentIn, animateIn, initialised, mainSiteOverlay, hovering]);

  React.useEffect(() => {
    if (active && !initialised && !mainSiteOverlay)
      setInitialised(true);
  }, [active, initialised, mainSiteOverlay]);

  const showTrigger = () => {
    if (!active)
      enterContactTriggerAction();
    if (!hovering)
      setHovering(true);
  };

  const handleTriggerMouseEnter = () => {
    if (!open) {
      showTrigger();
      if (!initialised) {
        setInitialised(true);
      }
    }
  };

  const handleTriggerMouseLeave = () => {
    if (!open) {
      if (active)
        leaveContactTriggerAction();
      if (hovering)
        setHovering(false);
    }
  };

  const handleCloseTriggerMouseEnter = () => {
    if (open) {
      setCloseHoverSpring({ active: 1 });
    }
  };

  const handleCloseTriggerMouseLeave = () => {
    if (open) {
      setCloseHoverSpring({ active: 0 });
    }
  };

  const handleTriggerMouseClick = () => {
    if (open)
      closeChatModalAction();
    else
      openChatModalAction();

    setCloseHoverSpring({ active: 0 });
  };

  const navSpringConfig: SpringConfig = {
    ...springConfig,
    tension: 170,
    friction: 36,
    clamp: false,
  };
  const [navSpring, setNavSpring] = useSpring(() => ({ x: 0, config: navSpringConfig }));

  React.useEffect(() => {
    setNavSpring({ x: navMenuOpen ? 1 : 0 });
  }, [navMenuOpen]);

  const wrapperStyles = {
    opacity: animateInSpring.active,
    transform: to([navSpring.x], nx => `translateY(${nx * 100}px)`),
    //right: windowSpring.x.to(x => x > 0 ? `50%` : null)
  };

  const triggerStyles = {
    width: to(
      [openHoverSpring.active, triggerWidthSpring.x, closeHoverSpring.active, windowSpring.x],
      (ox, tx, cx, wx) => `calc((${wx} * (50px - ${tx}px)) + (${cx} * 10px) + (${ox} * (100% - ${tx}px - (${(mainSiteOverlay ? 1 : 0)} * 2px))) + ${tx}px)`),
    height: to(
      [triggerHeightSpring.x, closeHoverSpring.active, windowSpring.x],
      (tx, cx, wx) => `calc((${wx} * (50px - 14px)) + (${cx} * 10px) + (${tx} * (100% - 14px - (${(mainSiteOverlay ? 1 : 0)} * 2px))) + 14px)`),
    transform: to(
      [windowSpring.x],
      wx => `translateY(-50%) translateX(${wx * 50}%)`),
  };

  const labelStyles = {
    opacity: openHoverSpring.active.to(x => mainSiteOverlay ? x : 0),
  };

  const closeModalStyles = {
    opacity: windowSpring.x.to(x => mainSiteOverlay && open ? x : 0),
  };

  const notificationStyles = {
    opacity: notificationSpring.x.to(x => x),
    transform: to([notificationSpring.x], nx => `translateX(50%) translateY(-50%) scale(${0.7 + (nx * 0.3)}, ${0.7 + (nx * 0.3)})`),
  };

  const chatIconStyles = {
    opacity: chatIconSpring.x,
  };

  const getTriggerText = () => {
    if (chatStarted)
      return 'Reopen conversation';
    else
      return 'Get in touch';
  };

  return (
    <React.Fragment>
      <a.div
        className={classNames(
          'chatModalTriggerWrapper',
          'backgroundShade-' + backgroundShade,
          { active },
          { open },
          { mainSiteOverlay },
        )}
        style={wrapperStyles}
        onMouseEnter={handleTriggerMouseEnter}
        onMouseLeave={handleTriggerMouseLeave}
      >
        <a.div
          className='label'
          //style={labelSpacerStyles}
        >
          {getTriggerText()}
        </a.div>
        <a.div
          className='interfaceTrigger'
          style={triggerStyles}
          onClick={handleTriggerMouseClick}
        >
          <a.div
            className='label'
            style={labelStyles}
          >
            {getTriggerText()}
          </a.div>
          <a.div className='chatBubbleIcon' style={chatIconStyles} />
          <a.div
            className='closeModalButton'
            style={closeModalStyles}
            onMouseEnter={handleCloseTriggerMouseEnter}
            onMouseLeave={handleCloseTriggerMouseLeave}
          >
            <div className='lines'>
              <span />
              <span />
            </div>
          </a.div>
          <div className='smallTrigger' />
        </a.div>
        <a.div
          className={classNames(
            'notification',
          )}
          style={notificationStyles}
        />
      </a.div>
    </React.Fragment>
  );
};

// Wire up the React component to the Redux store
export const ModalTrigger = ModalTriggerComponent;
