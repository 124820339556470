import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import { useSpring } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { ChatButtonLink } from './ChatButtonLink';
import classNames from 'classnames';

import { a } from 'react-spring';

import { useChatAnimation } from 'hooks/useChatAnimation';

export type ISender = 'qore' | 'friend';

export interface IProps {
  from: ISender;
  allowHtml: boolean;
  triggerStayScrolled: () => void;
}

export const ModalMessage: React.FC<IProps> = ({
  from,
  allowHtml = false,
  triggerStayScrolled = () => null,
  ...props
}) => {

  const [positionSpring, setPositionSpring] = useSpring(() => ({ x: 1, config: defaultSpringConfig }));

  const [
    messageContainerRef,
    initialised,
    heightSpring,
    inputVisible,
  ] = useChatAnimation(
    triggerStayScrolled,
    true,
  );

  React.useEffect(() => {
    if (inputVisible && !initialised)
      setPositionSpring({ x: 0 });
  }, [inputVisible, initialised]);

  const wrapperStyles = {
    height: !initialised ? heightSpring.x.to(x => x) : null,
  };

  const messageStyles = {
    transform: positionSpring.x.to(x => `translateY(calc((100% + 100px) * ${x}))`),
  };

  //const transformLinkUri = (uri: string) => {
  //  const newUri = uri.toString();
  //  //return '/services';
  //  return newUri;
  //}

  // Based on https://github.com/robinweser/react-markdown-github-renderers/blob/master/src/index.js
  // This renders links as my own react link elements to prevent hard page rerenders like the default links were causing
  const markdownRenderer = {
    link: ChatButtonLink,
    //blockquote: BlockQuote,
    //list: List,
    //listItem: ListItem,
    //paragraph: Paragraph,
    //strong: Strong,
    //heading: Heading,
    //inlineCode: InlineCode,
    //code: Code,
  };

  return (
    <a.div
      className={classNames(
        'messageWrapper',
        from,
        { lightBackgroundShade: from === 'friend' },
      )}
      style={wrapperStyles}
    >
      <a.div
        ref={messageContainerRef}
        className='messageContainer'
        style={messageStyles}
      >
        <div className='profileImage' />
        <div className='message'>
          <ReactMarkdown
            escapeHtml={!allowHtml}
            //transformLinkUri={transformLinkUri}
            renderers={markdownRenderer}
          >
            {props.children}
          </ReactMarkdown>
        </div>
      </a.div>
    </a.div>
  );
};
