import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as fromInterfaceOverlay from 'store/InterfaceOverlay';

import { BlockLink, IButtonLinkProps } from 'components/Links/Index';

interface IOwnSpecificProps {
  href: string;
}

type IOwnProps = IButtonLinkProps & IOwnSpecificProps;

type IProps = IOwnProps;

export const ChatButtonLink: React.FC<IProps> = ({ href = null }) => {
  const open = useSelector(fromInterfaceOverlay.getChatModalWindow);

  const dispatch = useDispatch();

  const closeChatModalAction = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.closeChatModalAction())
    , [dispatch]);

  const handleClick = () => {
    if (open)
      closeChatModalAction();
  };

  return (
    <BlockLink {...props} to={href} onClick={handleClick} />
  );
};
