import * as React from 'react';
import { useSpring, SpringValue } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { useLatest } from 'utils/Index';

interface ISpringInterface {
  [x: string]: SpringValue<any>;
  x: SpringValue<number>;
}

export const useChatAnimation = (
  triggerStayScrolled = () => null,
  inputVisibility = false,
) => {
  const [inputVisible, setInputVisible] = React.useState(false);
  const [initialised, setInitialised] = React.useState(false);

  const elementRef = React.useRef(null);

  const latestInputVisible = useLatest(inputVisible);

  const heightAnimationRest = () => {
    if (latestInputVisible)
      setInitialised(true);

    triggerStayScrolled();
  };

  const [heightSpring, setHeightSpring] = useSpring(() => ({
    x: 0,
    config: defaultSpringConfig,
    onRest: () => { heightAnimationRest(); },
  }));

  React.useEffect(() => {
    const elementHeight = elementRef.current.getBoundingClientRect().height;
    if (inputVisible && !initialised) {
      if (elementHeight !== 0)
        setHeightSpring({ x: elementHeight });
      else
        setInitialised(true);
    } else if (!inputVisible && initialised) {
      setHeightSpring({ x: 0, from: { x: elementHeight } });
      setInitialised(false);
    }
  }, [inputVisible, initialised]);

  React.useEffect(() => {
    setInputVisible(inputVisibility);
  }, [inputVisibility]);

  const showInput = (val: boolean = false) => {
    setInputVisible(val);
  };

  let returnValues: [React.MutableRefObject<any>, boolean, ISpringInterface, boolean, (val: boolean) => void];
  returnValues = [elementRef, initialised, heightSpring, inputVisible, showInput];

  return returnValues;
};

