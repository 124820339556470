import * as React from 'react';

import invariant from 'tiny-invariant';

// hook based on react-stay-scrolled@^7.0.0
// https://github.com/perrin4869/react-stay-scrolled#readme
// The original hook didn't check for a null ref so I modified it

export const currentScrollTop = dom => dom.scrollTop;
export const maxScrollTop = dom => dom.scrollHeight - dom.clientHeight;
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect;

export const useStayScrolled = (
  initialScroll = null,
  inaccuracy = 0,
  runScroll = null, // eslint-disable-line no-param-reassign
) => {
  const wasScrolled = React.useRef(null);
  const domRef = React.useRef(null);

  const elementDefined = () => {
    return domRef.current !== null;
  };

  const isScrolled = React.useCallback(
    () => elementDefined() ? Math.ceil(domRef.current.scrollTop) >= maxScrollTop(domRef.current) - inaccuracy : false,
    [inaccuracy],
  );

  React.useEffect(() => {
    if (elementDefined()) {
      const onScroll = () => { wasScrolled.current = isScrolled(); };

      domRef.current.addEventListener('scroll', onScroll);
      return () => domRef.current.removeEventListener('scroll', onScroll);
    }
  }, [domRef.current]);

  const defaultRunScroll = offset => {
    if (elementDefined())
      domRef.current.scrollTop = offset;
  };

  const scroll = React.useCallback(position => {
    //invariant(elementDefined(), `Trying to scroll to the bottom, but no element was found.
    //  Did you call this scrollBottom before the component with this hook finished mounting?`);

    //console.log('useStayScrolled --------------------------------------------------------------------');
    //console.log('useStayScrolled actually scrolling now');
    //console.log('useStayScrolled --------------------------------------------------------------------');

    if (elementDefined()) {
      const currentOffset = currentScrollTop(domRef.current);
      const offset = Math.min(maxScrollTop(domRef.current), position);
      if (currentOffset !== offset) {
        if (runScroll)
          runScroll(offset);
        else
          defaultRunScroll(offset);
      }
    }
  }, [runScroll]);

  const scrollBottom = React.useCallback(() => {
    scroll(Infinity);
  }, [scroll]);

  const stayScrolled = React.useCallback(() => {
    if (wasScrolled.current) scrollBottom();

    return wasScrolled.current;
  }, [scrollBottom]);

  useIsomorphicLayoutEffect(() => {
    if (initialScroll !== null) {
      scroll(initialScroll);
    }

    wasScrolled.current = isScrolled();
  }, []);

  let returnValues: [
    React.MutableRefObject<any>,
    (position: any) => void,
    () => any,
    () => void,
    () => boolean,
  ];
  returnValues = [
    domRef,
    scroll,
    stayScrolled,
    scrollBottom,
    isScrolled,
  ];

  return returnValues;
};
