import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { api } from 'api/api';

import * as EmailValidator from 'email-validator';

import { withCookies, ReactCookieProps } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

import classNames from 'classnames';

import { useMeasure, useLocalStorage } from "react-use";
import { useStayScrolled } from 'hooks/useStayScrolled';

import { useTransition, useSpring, a, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';
import { useGesture, useDrag } from 'react-use-gesture';

import { ModalTrigger } from './ModalTrigger';
import { ModalMessage } from './ModalMessage';
import { InputShortText } from './ChatInputs/InputShortText';
import { InputLongText } from './ChatInputs/InputLongText';
import { RadioButtons, IRadioOption } from './ChatInputs/RadioButtons';
import { Checkboxes, ICheckboxOption } from './ChatInputs/Checkboxes';

import * as fromInterfaceOverlay from 'store/InterfaceOverlay';

import SimpleLink from 'components/Links/SimpleLink/SimpleLink';

import { uuidv4, useTimeout, useLatest, usePrevious } from 'utils/Index';

export type IBackgroundShade = 'light' | 'dark';

// const localStorageIsAvailable = (typeof localStorage === 'undefined') ? false : true;

export interface IInputCommonProps {
  id: string;
}

type IInputValidationOptions = 'email';

export interface IInputShortTextProps extends IInputCommonProps {
  placeholder?: string;
  validation?: IInputValidationOptions;
}

export interface IInputLongTextProps extends IInputCommonProps {
  placeholder?: string;
}

export interface IInputRadioButtonsProps extends IInputCommonProps {
  options: IRadioOption[];
}

export interface IInputCheckboxesProps extends IInputCommonProps {
  options: ICheckboxOption[];
}

export interface IScriptStepMessage {
  id: string;
  from: fromInterfaceOverlay.IMessageFrom;
  message: string;
  allowHtml?: boolean;
  writingTime: number;
}

export interface IScriptStepInput {
  id: string;
  title: string;
  save: boolean;
  type: fromInterfaceOverlay.IInputTypes;
  props: IInputShortTextProps | IInputLongTextProps | IInputRadioButtonsProps | IInputCheckboxesProps;
}

type IScriptStepTypes = 'message' | 'input' | 'submitChat';

interface IScriptStepNextStepOption {
  stepId: string;
  options: string[];
}

export interface IScriptStep {
  id: string;
  type: IScriptStepTypes;
  contentId: string;
  nextStepId: string | IScriptStepNextStepOption | IScriptStepNextStepOption[];
}

interface ILocalChatState {
  timeUpdated: Date;
  id: string;
  messages: fromInterfaceOverlay.IMessage[];
  formValues: fromInterfaceOverlay.IFormValue[];
  currentScriptStepId: string;
  nextScriptStepId: string;
  currentUserInputId: string;
  //chatStarted: boolean;
}

export interface IOwnProps {
  backgroundShade: IBackgroundShade;
  mainSiteOverlay: boolean;
}

type IProps = IOwnProps & ReactCookieProps;

const getStandardWritingTime = () => (1000 + (Math.random() * 1000));

const scriptMessages: IScriptStepMessage[] = [
  {
    id: 'intro',
    from: 'qore',
    message: 'Hey, I’m Hasan. Don’t tell anyone but I’m just a bot 🤖! You can chat to me if you want or even give me details of a project you have coming up. What can I call you?',
    writingTime: 500,
  },
  {
    id: 'fullName',
    from: 'friend',
    message: 'I like to go by ###$$$fullName$$$###',
    writingTime: 0,
  },
  {
    id: 'fullNameResponse',
    from: 'qore',
    message: 'Hi ###$$$fullName$$$###!',
    writingTime: 1500,
  },
  {
    id: 'getTopic',
    from: 'qore',
    message: 'Did you just want to check me out or did you have something else in mind?',
    writingTime: 1200,
  },
  {
    id: 'topicProject',
    from: 'friend',
    message: "I've got a new project in mind and need you on board.",
    writingTime: 0,
  },
  {
    id: 'topicSubscription',
    from: 'friend',
    message: "Let's just say we're talking about an ongoing relationship here. Not a one off.",
    writingTime: 0,
  },
  {
    id: 'topicHi',
    from: 'friend',
    message: "Honestly? I just wanted to hi.",
    writingTime: 0,
  },
  {
    id: 'topicProjectResponse',
    from: 'qore',
    message: "That's cool. A project you say. Love it.",
    writingTime: 1300,
  },
  {
    id: 'topicSubscriptionResponse',
    from: 'qore',
    message: "I love that kind of confidence and I think we can make it work.",
    writingTime: 1400,
  },
  {
    id: 'topicHiResponse',
    from: 'qore',
    message: "Aaaaaah that's nice. People think that just because I'm a bot that I don't like conversation.",
    writingTime: 1200,
  },
  {
    id: 'getInterests',
    from: 'qore',
    message: 'Aaaaaaaaaand what kind of work are we talking about?',
    writingTime: 1500,
  },
  {
    id: 'servicesDefault',
    from: 'friend',
    message: "A few things you know. It'll be easier for me to just list them: ###$$$services$$$###.",
    writingTime: 0,
  },
  {
    id: 'servicesBranding',
    from: 'friend',
    message: "I'm just here for some branding.",
    writingTime: 0,
  },
  {
    id: 'servicesWebsite',
    from: 'friend',
    message: "A website would do me just fine.",
    writingTime: 0,
  },
  {
    id: 'servicesApp',
    from: 'friend',
    message: "Apps are all the rage now and I want in. You know how it is.",
    writingTime: 0,
  },
  {
    id: 'servicesOther',
    from: 'friend',
    message: "Something special. Something else.",
    writingTime: 0,
  },
  {
    id: 'servicesDefaultResponse',
    from: 'qore',
    message: "You don't half want much do you? That's cool. I like someone who knows what they want.",
    writingTime: 2000,
  },
  {
    id: 'servicesBrandingResponse',
    from: 'qore',
    message: "Ok then. I can sort that out for you.",
    writingTime: 1600,
  },
  {
    id: 'servicesWebsiteResponse',
    from: 'qore',
    message: "Aaaaah yes. The classic option.",
    writingTime: 1000,
  },
  {
    id: 'servicesAppResponse',
    from: 'qore',
    message: "There's something about apps that really elevate a brand experience. I'm sure we could hook you right up.",
    writingTime: 1200,
  },
  {
    id: 'servicesOtherResponse',
    from: 'qore',
    message: "Ooooh, that's interesting, but a little vague. You could've given me a few more details.",
    writingTime: 1700,
  },
  {
    id: 'getBudgetIntro',
    from: 'qore',
    message: "It would be a massive help if you could give me your initial budget.",
    writingTime: 800,
  },
  {
    id: 'getBudget',
    from: 'qore',
    message: "I mean, it doesn't have to be exact. A rough one is fine.",
    writingTime: 600,
  },
  {
    id: 'budget10k',
    from: 'friend',
    message: "I'm currently looking at a figure of 10k and under.",
    writingTime: 0,
  },
  {
    id: 'budget25k',
    from: 'friend',
    message: "I'd say it's in between 10k and 25k. That's me being honest right there.",
    writingTime: 0,
  },
  {
    id: 'budget50k',
    from: 'friend',
    message: "Since you asked, we were thinking anywhere between 25k and 50k.",
    writingTime: 0,
  },
  {
    id: 'budget100k',
    from: 'friend',
    message: "We're ready to invest up to 100k into this.",
    writingTime: 0,
  },
  {
    id: 'budget100kPlus',
    from: 'friend',
    message: "100k and upwards. We want to do this right.",
    writingTime: 0,
  },
  {
    id: 'budgetDefaultResponse',
    from: 'qore',
    message: "That's great. That'll really help us to get a feel for the scope of your project so we can get back to you with something appropriate.",
    writingTime: 2000,
  },
  {
    id: 'getDeadline',
    from: 'qore',
    message: "Do you have a launch date in mind or any sort of deadline?",
    writingTime: 800,
  },
  {
    id: 'deadline',
    from: 'friend',
    message: '###$$$deadline$$$###',
    writingTime: 0,
  },
  {
    id: 'noice',
    from: 'qore',
    message: 'Noice.',
    writingTime: 500,
  },
  {
    id: 'getDetails',
    from: 'qore',
    message: 'Anything else that you think might be important and could help us get a better grasp of what you wanted?',
    writingTime: 1300,
  },
  {
    id: 'details',
    from: 'friend',
    message: '###$$$details$$$###',
    writingTime: 0,
  },
  {
    id: 'detailsDefaultResponse',
    from: 'qore',
    message: "Now we're getting somewhere. Just a few more things.",
    writingTime: 1100,
  },
  {
    id: 'getOrganisation',
    from: 'qore',
    message: 'Are you speaking on behalf of an organisation or is it just you? A company name would be great 😜',
    writingTime: 1700,
  },
  {
    id: 'organisation',
    from: 'friend',
    message: '###$$$organisation$$$###',
    writingTime: 0,
  },
  {
    id: 'getEmail',
    from: 'qore',
    message: "I'll let the team know what we were chatting about so we can get things moving. Just need an email address!",
    writingTime: 1300,
  },
  {
    id: 'email',
    from: 'friend',
    message: 'You can get me at ###$$$email$$$###.',
    writingTime: 0,
  },
  {
    id: 'emailDefaultResponse',
    from: 'qore',
    //message: "💖![alt-text][heart-emoji] That's great ###$$$fullName$$$###. Thumbs up.",
    message: "💖 That's great ###$$$fullName$$$###. Thumbs up.",
    writingTime: 700,
  },
  {
    id: 'thanks',
    from: 'qore',
    message: "I’m really excited that we could be working together in the near future and one of our crew will get back to you in no time. In other words, I wouldn’t be surprised if you got a reply within a few days!",
    writingTime: 2000,
  },
  {
    id: 'ourProjects',
    from: 'qore',
    message: `In the meantime, you could check out a few of our latest projects, you don’t have to, but you could 👀:
              \n\n [![alt text](/assets/images/work/podiumValuations/chatProjectBackground.jpg "Podium Valuations")<label>Podium Valuations</label>](/work/podiumValuations) [![alt text](/assets/images/work/citiesOfTheFuture/chatProjectBackground.jpg "Cities of the future")<label>Cities of the future</label>](/work/citiesOfTheFuture)`,
    allowHtml: true,
    writingTime: 2000,
  },
  {
    id: 'restartChat',
    from: 'friend',
    message: "Hold on a second, I think I made a mistake 🙃. Can we start again?",
    writingTime: 0,
  },
  {
    id: 'restartChatResponse',
    from: 'qore',
    message: "👍 Don't worry about it, it happens. We can start right from the beginning and I'll pretend I don't know you!",
    writingTime: 1200,
  },
  {
    id: 'getFullName',
    from: 'qore',
    message: "I’m Hasan and I'm just a bot as you know. You can chat to me if you want or even give me details of a project you have coming up. What can I call you?",
    writingTime: 1200,
  },
  {
    id: 'submitFormError',
    from: 'qore',
    message: "I tried to getting in contact with the team but something went wrong on our end 😔. Would you mind trying again?",
    writingTime: 700,
  },
  {
    id: 'hiIAmABot',
    from: 'qore',
    message: "I mean, I am a bot.",
    writingTime: 1100,
  },
  {
    id: 'hiEverythingScripted',
    from: 'qore',
    message: "And everything I say *has* been scripted. Nobody really talks like this.",
    writingTime: 1100,
  },
  {
    id: 'hiIfeelTheSameWay',
    from: 'friend',
    message: "That’s ok. I sometimes feel the same way.",
    writingTime: 0,
  },
  {
    id: 'hiIAmHuman',
    from: 'friend',
    message: "And I’m a human being.",
    writingTime: 0,
  },
  {
    id: 'hiIGetWeird',
    from: 'qore',
    message: "I get really weird when I talk to people. But that’s literally all I’m allowed to do. Imagine if I could talk to other bots.",
    writingTime: 800,
  },
  {
    id: 'hiTurnIntoAMonster',
    from: 'friend',
    message: "You’d probably turn into a monster that tried to take over the world or something. Has nobody taught you not to scare people like that?",
    writingTime: 0,
  },
  {
    id: 'hiCodeMeCool',
    from: 'qore',
    message: "I’m trying really hard, the guys who coded me tried to make me cool 😎. It clearly didn’t work.",
    writingTime: 1500,
  },
  {
    id: 'hiItDidntWork',
    from: 'friend',
    message: "You’re right. It didn’t.",
    writingTime: 0,
  },
  {
    id: 'hiWow',
    from: 'qore',
    message: "Wow 😮",
    writingTime: 1400,
  },
  {
    id: 'hiThisIsAwkward',
    from: 'qore',
    message: "Now this is awkward.",
    writingTime: 700,
  },
  {
    id: 'hiGetDetails',
    from: 'qore',
    message: "How about you just give me a message I can let ‘real humans’ look at.",
    writingTime: 1300,
  },
  {
    id: 'hiDetails',
    from: 'friend',
    message: '###$$$hiDetails$$$###',
    writingTime: 0,
  },
  {
    id: 'hiOneMoreThing',
    from: 'qore',
    message: "Right. Just one more thing.",
    writingTime: 700,
  },
  {
    id: 'hiGetEmail',
    from: 'qore',
    message: "I can let the team know what we’ve been chatting about once you’ve given me your email address.",
    writingTime: 1200,
  },
  {
    id: 'hiSentDeets',
    from: 'qore',
    message: "I’ve sent the deets you gave on.",
    writingTime: 1000,
  },
  {
    id: 'hiTired',
    from: 'qore',
    message: "Well I suppose I’ll go have a lie down or something.",
    writingTime: 1800,
  },
  {
    id: 'hiOurProjects',
    from: 'qore',
    message: `You could check out a few of our latest projects, you don’t have to, but you could 👀:
              \n\n [![alt text](/assets/images/work/podiumValuations/chatProjectBackground.jpg "Podium Valuations")<label>Podium Valuations</label>](/work/podiumValuations) [![alt text](/assets/images/work/citiesOfTheFuture/chatProjectBackground.jpg "Cities of the future")<label>Cities of the future</label>](/work/citiesOfTheFuture)`,
    writingTime: 1500,
  },
  {
    id: 'anythingElseToDo',
    from: 'qore',
    message: "I mean, it's not like you have anything else to do.",
    writingTime: 2500,
  },
];

const scriptInputs: IScriptStepInput[] = [
  {
    id: 'fullName',
    title: 'Full Name',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'fullName',
      placeholder: 'Yay. Enter your full name in here.',
    },
  },
  {
    id: 'topic',
    title: 'Topic',
    save: true,
    type: 'radioButtons',
    props: {
      id: 'topic',
      options: [
        {
          id: 'project',
          text: 'A new project I have in mind',
        },
        {
          id: 'subscription',
          text: 'An ongoing design subscription for my needs',
        },
        {
          id: 'hi',
          text: 'Nothing in particular, just wanted to say hi!',
        },
      ],
    },
  },
  {
    id: 'services',
    title: 'Services',
    save: true,
    type: 'checkboxes',
    props: {
      id: 'services',
      options: [
        {
          id: 'branding',
          text: 'Branding',
        },
        {
          id: 'website',
          text: 'Website Design/Development',
        },
        {
          id: 'app',
          text: 'Application Design/Development',
        },
        {
          id: 'other',
          text: 'Something slightly different',
        },
      ],
    },
  },
  {
    id: 'budget',
    title: 'Budget',
    save: true,
    type: 'radioButtons',
    props: {
      id: 'budget',
      options: [
        {
          id: '10k',
          text: '£10,000 and under',
        },
        {
          id: '25k',
          text: '£10,000 - £25,000',
        },
        {
          id: '50k',
          text: '£25,000 - £50,000',
        },
        {
          id: '100k',
          text: '£50,000 - £100,000',
        },
        {
          id: '100kPlus',
          text: '£100,000 and above',
        },
      ],
    },
  },
  {
    id: 'deadline',
    title: 'Deadline',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'deadline',
      placeholder: 'A rough date or timeframe that you have.',
    },
  },
  {
    id: 'details',
    title: 'Details',
    save: true,
    type: 'inputLongText',
    props: {
      id: 'details',
      placeholder: 'Any more details that might be useful. (shift+enter for a new line)',
    },
  },
  {
    id: 'organisation',
    title: 'Organisation',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'organisation',
      placeholder: "Organisation's name or just you are both fine!",
    },
  },
  {
    id: 'email',
    title: 'Email',
    save: true,
    type: 'inputShortText',
    props: {
      id: 'email',
      placeholder: "An email address we can get in touch with you at.",
      validation: 'email',
    },
  },
  {
    id: 'submitChatAgain',
    title: 'Chat Submitted Multiple Times',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'submitChatAgain',
      options: [
        {
          id: 'tryAgain',
          text: 'Try again',
        },
      ],
    },
  },
  {
    id: 'hiIfeelTheSameWay',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiIfeelTheSameWay',
      options: [
        {
          id: 'hiIfeelTheSameWay',
          text: 'I feel the same way',
        },
      ],
    },
  },
  {
    id: 'hiIAmHuman',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiIAmHuman',
      options: [
        {
          id: 'hiIAmHuman',
          text: `And I'm human`,
        },
      ],
    },
  },
  {
    id: 'hiTurnIntoAMonster',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiTurnIntoAMonster',
      options: [
        {
          id: 'hiTurnIntoAMonster',
          text: 'Probably turn into a monster or something',
        },
      ],
    },
  },
  {
    id: 'hiItDidntWork',
    title: '',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'hiItDidntWork',
      options: [
        {
          id: 'hiItDidntWork',
          text: `You're right`,
        },
      ],
    },
  },
  {
    id: 'hiDetails',
    title: 'Details',
    save: true,
    type: 'inputLongText',
    props: {
      id: 'hiDetails',
      placeholder: 'Anything you want to say to us! (shift+enter for a new line)',
    },
  },
  {
    id: 'hiSubmitChatAfterEmailTryAgain',
    title: 'Chat Submitted Multiple Times',
    save: false,
    type: 'radioButtons',
    props: {
      id: 'submitChatAgain',
      options: [
        {
          id: 'tryAgain',
          text: 'Try again',
        },
      ],
    },
  },
];

const scriptSteps: IScriptStep[] = [
  {
    id: 'intro',
    type: 'message',
    contentId: 'intro',
    nextStepId: 'fullName',
  },
  {
    id: 'fullName',
    type: 'input',
    contentId: 'fullName',
    nextStepId: 'fullNameMessage',
  },
  {
    id: 'fullNameMessage',
    type: 'message',
    contentId: 'fullName',
    nextStepId: 'fullNameResponse',
  },
  {
    id: 'fullNameResponse',
    type: 'message',
    contentId: 'fullNameResponse',
    nextStepId: 'getTopic',
  },
  {
    id: 'getTopic',
    type: 'message',
    contentId: 'getTopic',
    nextStepId: 'topic',
  },
  {
    id: 'topic',
    type: 'input',
    contentId: 'topic',
    nextStepId: [
      {
        stepId: 'topicProject',
        options: [
          'project',
        ],
      },
      {
        stepId: 'topicSubscription',
        options: [
          'subscription',
        ],
      },
      {
        stepId: 'topicHi',
        options: [
          'hi',
        ],
      },
    ],
  },
  {
    id: 'topicProject',
    type: 'message',
    contentId: 'topicProject',
    nextStepId: 'topicProjectResponse',
  },
  {
    id: 'topicSubscription',
    type: 'message',
    contentId: 'topicSubscription',
    nextStepId: 'topicSubscriptionResponse',
  },
  {
    id: 'topicHi',
    type: 'message',
    contentId: 'topicHi',
    nextStepId: 'topicHiResponse',
  },
  {
    id: 'topicProjectResponse',
    type: 'message',
    contentId: 'topicProjectResponse',
    nextStepId: 'getInterests',
  },
  {
    id: 'topicSubscriptionResponse',
    type: 'message',
    contentId: 'topicSubscriptionResponse',
    nextStepId: 'getInterests',
  },
  {
    id: 'topicHiResponse',
    type: 'message',
    contentId: 'topicHiResponse',
    nextStepId: 'hiIAmABot',
  },
  {
    id: 'getInterests',
    type: 'message',
    contentId: 'getInterests',
    nextStepId: 'services',
  },
  {
    id: 'services',
    type: 'input',
    contentId: 'services',
    nextStepId: [
      {
        stepId: 'servicesDefault',
        options: [
          'default',
        ],
      },
      {
        stepId: 'servicesBranding',
        options: [
          'branding',
        ],
      },
      {
        stepId: 'servicesWebsite',
        options: [
          'website',
        ],
      },
      {
        stepId: 'servicesApp',
        options: [
          'app',
        ],
      },
      {
        stepId: 'servicesOther',
        options: [
          'other',
        ],
      },
    ],
  },
  {
    id: 'servicesDefault',
    type: 'message',
    contentId: 'servicesDefault',
    nextStepId: 'servicesDefaultResponse',
  },
  {
    id: 'servicesBranding',
    type: 'message',
    contentId: 'servicesBranding',
    nextStepId: 'servicesBrandingResponse',
  },
  {
    id: 'servicesWebsite',
    type: 'message',
    contentId: 'servicesWebsite',
    nextStepId: 'servicesWebsiteResponse',
  },
  {
    id: 'servicesApp',
    type: 'message',
    contentId: 'servicesApp',
    nextStepId: 'servicesAppResponse',
  },
  {
    id: 'servicesOther',
    type: 'message',
    contentId: 'servicesOther',
    nextStepId: 'servicesOtherResponse',
  },
  {
    id: 'servicesDefaultResponse',
    type: 'message',
    contentId: 'servicesDefaultResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesBrandingResponse',
    type: 'message',
    contentId: 'servicesBrandingResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesWebsiteResponse',
    type: 'message',
    contentId: 'servicesWebsiteResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesAppResponse',
    type: 'message',
    contentId: 'servicesAppResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'servicesOtherResponse',
    type: 'message',
    contentId: 'servicesOtherResponse',
    nextStepId: 'getBudgetIntro',
  },
  {
    id: 'getBudgetIntro',
    type: 'message',
    contentId: 'getBudgetIntro',
    nextStepId: 'getBudget',
  },
  {
    id: 'getBudget',
    type: 'message',
    contentId: 'getBudget',
    nextStepId: 'budget',
  },
  {
    id: 'budget',
    type: 'input',
    contentId: 'budget',
    nextStepId: [
      {
        stepId: 'budget10k',
        options: [
          '10k',
        ],
      },
      {
        stepId: 'budget25k',
        options: [
          '25k',
        ],
      },
      {
        stepId: 'budget50k',
        options: [
          '50k',
        ],
      },
      {
        stepId: 'budget100k',
        options: [
          '100k',
        ],
      },
      {
        stepId: 'budget100kPlus',
        options: [
          '100kPlus',
        ],
      },
    ],
  },
  {
    id: 'budget10k',
    type: 'message',
    contentId: 'budget10k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget25k',
    type: 'message',
    contentId: 'budget25k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget50k',
    type: 'message',
    contentId: 'budget50k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget100k',
    type: 'message',
    contentId: 'budget100k',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budget100kPlus',
    type: 'message',
    contentId: 'budget100kPlus',
    nextStepId: 'budgetDefaultResponse',
  },
  {
    id: 'budgetDefaultResponse',
    type: 'message',
    contentId: 'budgetDefaultResponse',
    nextStepId: 'getDeadline',
  },
  {
    id: 'getDeadline',
    type: 'message',
    contentId: 'getDeadline',
    nextStepId: 'deadline',
  },
  {
    id: 'deadline',
    type: 'input',
    contentId: 'deadline',
    nextStepId: 'deadlineMessage',
  },
  {
    id: 'deadlineMessage',
    type: 'message',
    contentId: 'deadline',
    nextStepId: 'detailsNoice',
  },
  {
    id: 'detailsNoice',
    type: 'message',
    contentId: 'noice',
    nextStepId: 'getDetails',
  },
  {
    id: 'getDetails',
    type: 'message',
    contentId: 'getDetails',
    nextStepId: 'details',
  },
  {
    id: 'details',
    type: 'input',
    contentId: 'details',
    nextStepId: 'detailsMessage',
  },
  {
    id: 'detailsMessage',
    type: 'message',
    contentId: 'details',
    nextStepId: 'detailsDefaultResponse',
  },
  {
    id: 'detailsDefaultResponse',
    type: 'message',
    contentId: 'detailsDefaultResponse',
    nextStepId: 'getOrganisation',
  },
  {
    id: 'getOrganisation',
    type: 'message',
    contentId: 'getOrganisation',
    nextStepId: 'organisation',
  },
  {
    id: 'organisation',
    type: 'input',
    contentId: 'organisation',
    nextStepId: 'organisationMessage',
  },
  {
    id: 'organisationMessage',
    type: 'message',
    contentId: 'organisation',
    nextStepId: 'getEmail',
  },
  {
    id: 'getEmail',
    type: 'message',
    contentId: 'getEmail',
    nextStepId: 'email',
  },
  {
    id: 'email',
    type: 'input',
    contentId: 'email',
    nextStepId: 'emailMessage',
  },
  {
    id: 'emailMessage',
    type: 'message',
    contentId: 'email',
    nextStepId: 'submitChatAfterEmail',
  },
  {
    id: 'submitChatAfterEmail',
    type: 'submitChat',
    contentId: 'submitChat',
    nextStepId: 'emailDefaultResponse',
  },
  {
    id: 'submitChatAfterEmailError',
    type: 'message',
    contentId: 'submitFormError',
    nextStepId: 'trySubmittingChatAgain',
  },
  {
    id: 'trySubmittingChatAgain',
    type: 'input',
    contentId: 'submitChatAgain',
    nextStepId: [
      {
        stepId: 'submitChatAfterEmail',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'emailDefaultResponse',
    type: 'message',
    contentId: 'emailDefaultResponse',
    nextStepId: 'thanks',
  },
  {
    id: 'thanks',
    type: 'message',
    contentId: 'thanks',
    nextStepId: 'ourProjects',
  },
  {
    id: 'ourProjects',
    type: 'message',
    contentId: 'ourProjects',
    nextStepId: 'anythingElseToDo',
  },
  {
    id: 'anythingElseToDo',
    type: 'message',
    contentId: 'anythingElseToDo',
    nextStepId: null,
  },
  {
    id: 'hiIAmABot',
    type: 'message',
    contentId: 'hiIAmABot',
    nextStepId: 'hiEverythingScripted',
  },
  {
    id: 'hiEverythingScripted',
    type: 'message',
    contentId: 'hiEverythingScripted',
    nextStepId: 'hiIfeelTheSameWay',
  },
  {
    id: 'hiIfeelTheSameWay',
    type: 'input',
    contentId: 'hiIfeelTheSameWay',
    nextStepId: [
      {
        stepId: 'hiIfeelTheSameWayMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiIfeelTheSameWayMessage',
    type: 'message',
    contentId: 'hiIfeelTheSameWay',
    nextStepId: 'hiIAmHuman',
  },
  {
    id: 'hiIAmHuman',
    type: 'input',
    contentId: 'hiIAmHuman',
    nextStepId: [
      {
        stepId: 'hiIAmHumanMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiIAmHumanMessage',
    type: 'message',
    contentId: 'hiIAmHuman',
    nextStepId: 'hiIGetWeird',
  },
  {
    id: 'hiIGetWeird',
    type: 'message',
    contentId: 'hiIGetWeird',
    nextStepId: 'hiTurnIntoAMonster',
  },
  {
    id: 'hiTurnIntoAMonster',
    type: 'input',
    contentId: 'hiTurnIntoAMonster',
    nextStepId: [
      {
        stepId: 'hiTurnIntoAMonsterMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiTurnIntoAMonsterMessage',
    type: 'message',
    contentId: 'hiTurnIntoAMonster',
    nextStepId: 'hiCodeMeCool',
  },
  {
    id: 'hiCodeMeCool',
    type: 'message',
    contentId: 'hiCodeMeCool',
    nextStepId: 'hiItDidntWork',
  },
  {
    id: 'hiItDidntWork',
    type: 'input',
    contentId: 'hiItDidntWork',
    nextStepId: [
      {
        stepId: 'hiItDidntWorkMessage',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiItDidntWorkMessage',
    type: 'message',
    contentId: 'hiItDidntWork',
    nextStepId: 'hiWow',
  },
  {
    id: 'hiWow',
    type: 'message',
    contentId: 'hiWow',
    nextStepId: 'hiThisIsAwkward',
  },
  {
    id: 'hiThisIsAwkward',
    type: 'message',
    contentId: 'hiThisIsAwkward',
    nextStepId: 'hiGetDetails',
  },
  {
    id: 'hiGetDetails',
    type: 'message',
    contentId: 'hiGetDetails',
    nextStepId: 'hiDetails',
  },
  {
    id: 'hiDetails',
    type: 'input',
    contentId: 'hiDetails',
    nextStepId: 'hiDetailsMessage',
  },
  {
    id: 'hiDetailsMessage',
    type: 'message',
    contentId: 'hiDetails',
    nextStepId: 'hiOneMoreThing',
  },
  {
    id: 'hiOneMoreThing',
    type: 'message',
    contentId: 'hiOneMoreThing',
    nextStepId: 'hiGetEmail',
  },
  {
    id: 'hiGetEmail',
    type: 'message',
    contentId: 'hiGetEmail',
    nextStepId: 'hiEmail',
  },
  {
    id: 'hiEmail',
    type: 'input',
    contentId: 'email',
    nextStepId: 'hiEmailMessage',
  },
  {
    id: 'hiEmailMessage',
    type: 'message',
    contentId: 'email',
    nextStepId: 'hiSubmitChatAfterEmail',
  },
  {
    id: 'hiSubmitChatAfterEmail',
    type: 'submitChat',
    contentId: 'submitChat',
    nextStepId: 'hiSentDeets',
  },
  {
    id: 'hiSubmitChatAfterEmailError',
    type: 'message',
    contentId: 'submitFormError',
    nextStepId: 'hiSubmitChatAfterEmailTryAgain',
  },
  {
    id: 'hiSubmitChatAfterEmailTryAgain',
    type: 'input',
    contentId: 'submitChatAgain',
    nextStepId: [
      {
        stepId: 'hiSubmitChatAfterEmail',
        options: [
          'default',
        ],
      },
    ],
  },
  {
    id: 'hiSentDeets',
    type: 'message',
    contentId: 'hiSentDeets',
    nextStepId: 'hiTired',
  },
  {
    id: 'hiTired',
    type: 'message',
    contentId: 'hiTired',
    nextStepId: 'hiOurProjects',
  },
  {
    id: 'hiOurProjects',
    type: 'message',
    contentId: 'hiOurProjects',
    nextStepId: 'anythingElseToDo',
  },
  {
    id: 'anythingElseToDo',
    type: 'message',
    contentId: 'anythingElseToDo',
    nextStepId: null,
  },
  {
    id: 'restartChat',
    type: 'message',
    contentId: 'restartChat',
    nextStepId: 'restartChatResponse',
  },
  {
    id: 'restartChatResponse',
    type: 'message',
    contentId: 'restartChatResponse',
    nextStepId: 'getFullName',
  },
  {
    id: 'getFullName',
    type: 'message',
    contentId: 'getFullName',
    nextStepId: 'fullName',
  },
];

const ModalWindowComponent: React.FC<IProps> = ({
  mainSiteOverlay,
  backgroundShade,
  cookies,
}) => {

  const [initialised, setInitialised] = React.useState(false);
  const previousInitialised = usePrevious(initialised);

  //const [messages, setMessages] = React.useState<fromInterfaceOverlay.IMessage[]>([]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Redux functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const active = useSelector(fromInterfaceOverlay.getChatActive);
  const open = useSelector(fromInterfaceOverlay.getChatModalWindow);
  const chatId = useSelector(fromInterfaceOverlay.getChatModalId);
  const chatStarted = useSelector(fromInterfaceOverlay.getChatModalStarted);

  const messages = useSelector(fromInterfaceOverlay.getChatModalMessages);
  const previousMessages: fromInterfaceOverlay.IMessage[] = usePrevious(messages);

  const formValues = useSelector(fromInterfaceOverlay.getChatModalFormValues);
  const latestFormValues = useLatest(formValues);

  const currentScriptStepId = useSelector(fromInterfaceOverlay.getChatModalCurrentScriptStepId);
  const nextScriptStepId = useSelector(fromInterfaceOverlay.getChatModalNextScriptStepId);
  const currentUserInputId = useSelector(fromInterfaceOverlay.getChatModalCurrentUserInputId);
  const userInputVisible = useSelector(fromInterfaceOverlay.getChatModalUserInputVisible);

  const triggerWidth = useSelector(fromInterfaceOverlay.getChatTriggerWidth);

  const dispatch = useDispatch();

  const closeChatModalAction = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.closeChatModalAction())
    , [dispatch]);

  const setChatId = React.useCallback(
    (id: string) => dispatch(fromInterfaceOverlay.actionCreators.setChatId(id))
    , [dispatch]);

  const startChat = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.startChat())
    , [dispatch]);

  const loadChatMessages = React.useCallback(
    (chatMessages: fromInterfaceOverlay.IMessage[]) => dispatch(fromInterfaceOverlay.actionCreators.loadChatMessages(chatMessages))
    , [dispatch]);

  const addChatMessage = React.useCallback(
    (message: fromInterfaceOverlay.IMessage) => dispatch(fromInterfaceOverlay.actionCreators.addChatMessage(message))
    , [dispatch]);

  const loadFormValues = React.useCallback(
    (newFormValues: fromInterfaceOverlay.IFormValue[]) => dispatch(fromInterfaceOverlay.actionCreators.loadFormValues(newFormValues))
    , [dispatch]);

  const addFormValue = React.useCallback(
    (formValue: fromInterfaceOverlay.IFormValue) => dispatch(fromInterfaceOverlay.actionCreators.addFormValue(formValue))
    , [dispatch]);

  const resetFormValues = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.resetFormValues())
    , [dispatch]);

  const updateCurrentScriptStepId = React.useCallback(
    (id: string, isCaller: boolean = true) => dispatch(fromInterfaceOverlay.actionCreators.updateCurrentScriptStepId(id, isCaller))
    , [dispatch]);

  const updateNextScriptStepId = React.useCallback(
    (id: string, isCaller: boolean = true) => dispatch(fromInterfaceOverlay.actionCreators.updateNextScriptStepId(id, isCaller))
    , [dispatch]);

  const updateCurrentUserInputId = React.useCallback(
    (id: string, isCaller: boolean = true) => dispatch(fromInterfaceOverlay.actionCreators.updateCurrentUserInputId(id, isCaller))
    , [dispatch]);

  const updateUserInputVisibility = React.useCallback(
    (visible: boolean) => dispatch(fromInterfaceOverlay.actionCreators.updateUserInputVisibility(visible))
    , [dispatch]);

  const markChatAsActive = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.markChatAsActive())
    , [dispatch]);

  const updateChatTriggerWidth = React.useCallback(
    (width: number) => dispatch(fromInterfaceOverlay.actionCreators.updateChatTriggerWidth(width))
    , [dispatch]);

  const addChatNotificationDot = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.addChatNotificationDot())
    , [dispatch]);

  const notificationDot = useSelector(fromInterfaceOverlay.getChatModalNotificationDot);
  const removeChatNotificationDot = React.useCallback(
    () => dispatch(fromInterfaceOverlay.actionCreators.removeChatNotificationDot())
    , [dispatch]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Window dimension change functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const windowWrapper = React.useRef<HTMLDivElement>(null);
  const [windowHeight, setWindowHeight] = React.useState(100);

  const triggerNotificationElement = React.useRef<HTMLDivElement>(null);

  const updateWindowDimensions = () => {
    if (mainSiteOverlay) {
      const triggerNotification = triggerNotificationElement.current;
      if (triggerNotification != null)
        updateChatTriggerWidth(triggerNotification.clientWidth);

      const windowWrapperProps = windowWrapper.current.getBoundingClientRect();
      const height = windowWrapperProps.height;
      setWindowHeight(height);
    }
  };

  React.useLayoutEffect(() => {

    updateWindowDimensions();

    // Setup event listeners on initial mount to keep the  dimensions of the slider updated
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat cookie functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const getEmptyLocalChatStateObject = (): ILocalChatState => ({
    timeUpdated: new Date(),
    id: null,
    messages: [],
    formValues: [],
    currentScriptStepId: null,
    nextScriptStepId: null,
    currentUserInputId: null,
  });
  const [localChatState, setLocalChatState] = useLocalStorage<ILocalChatState>('chatState', getEmptyLocalChatStateObject());

  // const [cookieOptions] = React.useState({
  //   path: '/',
  //   maxAge: 60 * 20, // 20mins
  //   secure: false,
  //   httpOnly: false,
  //   sameSite: true,
  // } as CookieSetOptions);

  React.useEffect(() => {
    if (mainSiteOverlay && active && initialised && messages.length > 0) {
      updateLocalChatState(
        chatId,
        messages,
        formValues,
        currentScriptStepId,
        nextScriptStepId,
        currentUserInputId,
        //chatStarted,
      );
    }
  }, [
    mainSiteOverlay,
    active,
    initialised,
    chatId,
    messages,
    formValues,
    currentScriptStepId,
    nextScriptStepId,
    currentUserInputId,
    //chatStarted,
  ]);

  React.useEffect(() => {
    if (mainSiteOverlay) {
      let newChatSession = true;
      let newChatId: string = null;
      try {
        if (localChatState.id !== null) {
          const expiryDate = new Date(localChatState.timeUpdated);
          expiryDate.setMinutes(expiryDate.getMinutes() + 20);
          // const expiryDate = new Date(localChatState.timeUpdated.getTime() + (20 * 60000)); // Add 20 minutes to the time updated for an expiry time for chat state
          // const expiryDate = new Date();
          if (new Date() < expiryDate) {
            newChatSession = false;
            newChatId = localChatState.id;
            if (localChatState.messages.length > 0 || localChatState.currentScriptStepId != null || localChatState.nextScriptStepId != null || localChatState.currentUserInputId != null) {
              // markChatAsActive(); // This will mark the chat as active so any chat with no messages can trigger the first step of the chat script, previously it would restore the chat from the cookie and assume that the user was the one we were waiting to send a message for
              // } else {
              loadChatMessages(localChatState.messages);
              loadFormValues(localChatState.formValues);
              updateCurrentScriptStepId(localChatState.currentScriptStepId, false);
              updateNextScriptStepId(localChatState.nextScriptStepId, false);
              updateCurrentUserInputId(localChatState.currentUserInputId, false);
            }
          } else {
            setLocalChatState(getEmptyLocalChatStateObject());
          }
        }
      }
      catch {
        newChatSession = true;
      }

      if (newChatSession) {
        newChatId = uuidv4();
        updateLocalChatState(
          newChatId,
          messages,
          formValues,
          currentScriptStepId,
          nextScriptStepId,
          currentUserInputId,
          //      chatStarted,
        );
      }
      setChatId(newChatId);
      setInitialised(true);
    }
  }, [mainSiteOverlay]);

  const updateLocalChatState = (
    cookieChatId: string,
    cookieMessages: fromInterfaceOverlay.IMessage[],
    cookieFormValues: fromInterfaceOverlay.IFormValue[],
    cookieCurrentScriptStepId: string,
    cookieNextScriptsStepId: string,
    cookeCurrentUserInputId: string,
    //cookieChatStarted: boolean,
  ) => {
    const chatState: ILocalChatState = {
      timeUpdated: new Date(),
      id: cookieChatId,
      messages: cookieMessages,
      formValues: cookieFormValues,
      currentScriptStepId: cookieCurrentScriptStepId,
      nextScriptStepId: cookieNextScriptsStepId,
      currentUserInputId: cookeCurrentUserInputId,
      //chatStarted: cookieChatStarted,
    };
    setLocalChatState(chatState);
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat modal functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 400,
  };

  const [overlaySpring, setOverlaySpring] = useSpring(() => ({ x: 0, config: springConfig }));
  const [windowHeightSpring, setWindowHeightSpring] = useSpring(() => ({
    y: 0,
    config: springConfig,
    immediate: false,
  }));
  const [windowWidthSpring, setWindowWidthSpring] = useSpring(() => ({
    x: 0,
    config: springConfig,
  }));

  React.useEffect(() => {
    setOverlaySpring({ x: open ? 1 : 0 });
    setWindowHeightSpring({ y: open ? 1 : 0 });
    setWindowWidthSpring({ x: open ? 1 : 0 });
    updateWindowDimensions();
  }, [open]);

  const closeChatModal = () => {
    if (open)
      closeChatModalAction();
  };

  React.useEffect(() => {
    if (mainSiteOverlay && initialised && !chatStarted && messages.length > 0) {
      startChat();
    }
  }, [mainSiteOverlay, initialised, chatStarted, messages.length]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat modal drag functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const dragBarBind = useGesture({
    onDrag: state => handleDragBarDrag(state),     // fires on drag
  });

  const handleDragBarDrag = state => {
    const { delta: [, deltaY], vxvy: [, vy], first, last, memo = windowHeightSpring.y.getValue() } = state;
    return updateWindowDragPositions(deltaY, vy, first, last, memo);
  };

  const chatContainerBind = useGesture({
    onDrag: state => handleChatContainerDrag(state),     // fires on drag
  });

  const handleChatContainerDrag = state => {
    const { delta: [, deltaY], vxvy: [, vy], first, last, cancel, memo = windowHeightSpring.y.getValue() } = state;
    const messagesContainer = messagesContainerRef.current;
    const currentScrollTop = messagesContainer.scrollTop;

    if (first && currentScrollTop !== 0)
      cancel();

    if (deltaY >= 0) {
      return updateWindowDragPositions(deltaY, vy, first, last, memo);
    }

    return memo;
  };

  const updateWindowDragPositions = (
    deltaY: number,
    vy: number,
    first: boolean,
    last: boolean,
    memo: any,
  ) => {
    const draggableHeight = windowHeight;

    let newY = (memo * draggableHeight) - deltaY;

    if (last)
      newY = newY - (vy * 100);

    if (newY > draggableHeight)
      newY = draggableHeight;
    else if (newY < 100)
      newY = 100;

    if (last) {
      if (newY < (draggableHeight / 2))
        newY = 0;
      else
        newY = draggableHeight;

      if (newY === 0) {
        setWindowHeightSpring({ y: 0, immediate: false });
        setWindowWidthSpring({ y: 0, immediate: false });
        closeChatModal();
      } else {
        setWindowHeightSpring({ y: newY / draggableHeight, immediate: false });
      }
    } else {
      setWindowHeightSpring({ y: newY / draggableHeight, immediate: true });
    }

    return memo;

  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Keep chat window scrolled functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [chatStayScrolled, setChatStayScrolled] = React.useState(0);

  const messagesWrapperRef = React.useRef(null);
  const [messagesMeasureRef, { height: messagesHeight }] = useMeasure();
  const [userInputMeasureRef, { height: userInputHeight }] = useMeasure();

  const [{ scrollTop }, updateScroll] = useSpring(() => ({ scrollTop: 0, config: defaultSpringConfig }));

  const runScroll = React.useCallback(offset => updateScroll({
    scrollTop: offset,
    from: { scrollTop: messagesContainerRef.current ? messagesContainerRef.current.scrollTop : 0 },
    immediate: isScrolled() ? true : false,
    reset: true,
  }), [updateScroll]);

  const [messagesContainerRef, scroll, stayScrolled, scrollBottom, isScrolled] = useStayScrolled(null, 30, runScroll);

  const triggerStayScrolled = () => {
    setChatStayScrolled(chatStayScrolled + 1);
  };

  React.useLayoutEffect(() => {
    if (mainSiteOverlay) {
      // Tell the user to scroll down to see the newest messages if the element wasn't scrolled down
      if (messages.length > 0) {
        const scrolledToBottom = stayScrolled();
        setNewMessageNotification(!scrolledToBottom);
      }
    }
  }, [mainSiteOverlay, messages.length]);

  React.useLayoutEffect(() => {
    if (mainSiteOverlay) {
      // Either keep the chat window scrolled to the bottom or scroll to the bottom whilst the heights of the windows are changing
      const scrolledToBottom = isScrolled();
      if (scrolledToBottom) {
        scrollBottom();
      } else {
        stayScrolled();
      }
    }
  }, [mainSiteOverlay, messagesHeight, userInputHeight]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      New message notification functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [newMessageNotification, setNewMessageNotification] = React.useState(false);
  const [newMessageSpring, setNewMessageSpring] = useSpring(() => ({ x: 0, config: defaultSpringConfig }));

  const removeNewMessageNotification = () => {
    setNewMessageNotification(false);
  };

  const onScroll = React.useCallback(() => {
    const scrolledToBottom = isScrolled();
    if (scrolledToBottom)
      removeNewMessageNotification();
  }, []);

  React.useEffect(() => { setNewMessageSpring({ x: newMessageNotification ? 1 : 0 }); }, [newMessageNotification]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Notification dot functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (notificationDot && open)
      removeChatNotificationDot();
    else {
      if (previousMessages != null)
        if (!open && (previousMessages.length !== messages.length))
          addChatNotificationDot();
    }
  }, [open, notificationDot, previousMessages, messages]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Chat script step functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (
      mainSiteOverlay
      && active
      && initialised
      && open
      && messages.length === 0
      && currentScriptStepId == null
      && nextScriptStepId == null
    ) {
      updateNextScriptStepId('intro'); // ourProjects // intro
    }
  }, [mainSiteOverlay, active, initialised, open, messages, currentScriptStepId, nextScriptStepId]);

  const getStepById = (id: string) => {
    const stepFromId = scriptSteps.filter(step => {
      return step.id === id;
    })[0];

    return stepFromId;
  };

  const getNextStepId = () => {
    const currentStep = getStepById(currentScriptStepId);
    const nextStepId = currentStep.nextStepId as string;
    return nextStepId;
  };

  const [userInputTimeout, setUserInputTimeout] = React.useState(false);
  const [userInputTimeoutInputId, setUserInputTimeoutInputId] = React.useState<string>(null);
  const latestUserInputTimeoutInputId = useLatest(userInputTimeoutInputId);

  useTimeout(() => {
    setUserInputTimeout(false);
    updateCurrentUserInputId(latestUserInputTimeoutInputId.current);
  }, userInputTimeout ? 1000 : null);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Trigger next script step functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const triggerNextScriptStep = () => {
    if (currentScriptStepId != null) {
      const currentStep = getStepById(currentScriptStepId);
      const nextStepId = currentStep.nextStepId;
      if (nextStepId != null)
        updateNextScriptStepId(nextStepId as string);
    }
  };

  React.useEffect(() => {
    if (mainSiteOverlay && active && (currentScriptStepId !== nextScriptStepId)) {
      triggerScriptStepFromId();
    }
  }, [mainSiteOverlay, active, currentScriptStepId, nextScriptStepId]);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Script step functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const triggerScriptStepFromId = () => {
    updateCurrentScriptStepId(nextScriptStepId);
    const nextStep = getStepById(nextScriptStepId);

    if (nextStep.type === "message") {
      const nextStepContent = scriptMessages.filter(message => {
        return message.id === nextStep.contentId;
      })[0];

      triggerNewMessage(
        {
          id: uuidv4(),
          from: nextStepContent.from,
          message: replaceMarkdownImages(nextStepContent.message),
          allowHtml: nextStepContent.allowHtml != null ? nextStepContent.allowHtml : false,
          time: null,
        },
        nextStepContent.writingTime,
      );
    } else if (nextStep.type === "input") {
      setUserInputTimeoutInputId(nextStep.contentId);
      setUserInputTimeout(true);
    } else if (nextStep.type === "submitChat") {
      setTriggerChatSubmission(true);
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      New message functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [newMessageTimeout, setNewMessageTimeout] = React.useState(false);
  const [newMessageTimeoutMessage, setNewMessageTimeoutMessage] = React.useState<fromInterfaceOverlay.IMessage>(null);
  const [newMessageTimeoutDelay, setNewMessageTimeoutDelay] = React.useState(0);

  const handleNewMessageTrigger = () => {
    triggerNewMessage(
      {
        id: uuidv4(),
        from: 'qore',
        message: '' + Math.random(),
        allowHtml: false,
        time: Date.now().toString(),
      },
    );
  };

  const triggerNewMessage = (newMessage: fromInterfaceOverlay.IMessage, delay: number = null) => {
    setNewMessageTimeoutMessage(newMessage);

    const messageDelay = delay != null ? delay : 0;
    setNewMessageTimeoutDelay(messageDelay);

    setNewMessageTimeout(true);
  };

  useTimeout(() => {
    setNewMessageTimeout(false);

    const newMessage = newMessageTimeoutMessage;
    let replacedMessage = newMessage.message;

    latestFormValues.current.map((formValue: fromInterfaceOverlay.IFormValue, index) => {
      if (formValue.type === "inputShortText") {
        const inputProps: fromInterfaceOverlay.IFormValueShortTextProps = formValue.props as fromInterfaceOverlay.IFormValueShortTextProps;
        replacedMessage = replaceMarkdownVariable(replacedMessage, formValue.id, inputProps.value);
      }
      else if (formValue.type === "inputLongText") {
        const inputProps: fromInterfaceOverlay.IFormValueLongTextProps = formValue.props as fromInterfaceOverlay.IFormValueLongTextProps;
        replacedMessage = replaceMarkdownVariable(replacedMessage, formValue.id, inputProps.value);
      }
      else if (formValue.type === "checkboxes") {
        const inputProps: fromInterfaceOverlay.IFormValueCheckboxesProps = formValue.props as fromInterfaceOverlay.IFormValueCheckboxesProps;
        let replacedMessageOptions = '';
        const replacedSelectedOptions: string[] = [];
        inputProps.value.map(value => {

          const currentInput = scriptInputs.filter(input => {
            return input.id === formValue.id;
          })[0];

          const currentInputProps: IInputCheckboxesProps = currentInput.props as IInputCheckboxesProps;

          const newCurrentOption = currentInputProps.options.filter(currentOption => {
            return currentOption.id === value;
          })[0];

          replacedSelectedOptions.push(newCurrentOption.text);

        });

        const amountOfOptions = replacedSelectedOptions.length;

        replacedSelectedOptions.map((selectedOption, optionIndex) => {
          if (optionIndex === 0)
            replacedMessageOptions += selectedOption;
          else if (optionIndex + 1 < amountOfOptions)
            replacedMessageOptions += ', ' + selectedOption;
          else
            replacedMessageOptions += ' and ' + selectedOption;
        });
        replacedMessage = replaceMarkdownVariable(replacedMessage, formValue.id, replacedMessageOptions);
      }
    });

    newMessage.message = replacedMessage;
    addChatMessage(newMessage);
    triggerNextScriptStep();
  }, newMessageTimeout ? newMessageTimeoutDelay : null);

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Markdown replacement functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const replaceMarkdownImages = (message: string) => {
    const replacedMessage = message.replace('[heart-emoji]', '(/assets/images/chatHeartEmoji.png "Heart Emoji")');
    return replacedMessage;
  };

  const replaceMarkdownVariable = (message: string, id: string, value: string) => {
    return message.replace(`###$$$${id}$$$###`, value);
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Restart chat functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const restartChat = () => {
    updateUserInputVisibility(false);
    updateCurrentUserInputId(null);
    setNewMessageTimeout(false);
    updateNextScriptStepId('restartChat');
    resetFormValues();
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Show message list functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const getMessages = () => {
    return messages.map((message, index) => (
      <ModalMessage
        key={index}
        from={message.from}
        allowHtml={message.allowHtml}
        triggerStayScrolled={triggerStayScrolled}
      >
        {message.message}
      </ModalMessage>
    ));
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Input validation functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const validateEmail = (value: string, touched: boolean, submitted: boolean) => {
    return touched && submitted ? EmailValidator.validate(value) : true;
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Input submit functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [inputSubmitNextStepId, setInputSubmitNextStepId] = React.useState<string>(null);
  const latestInputSubmitNextStepId = useLatest(inputSubmitNextStepId);
  const [inputSubmitClicked, setInputSubmitClicked] = React.useState(false);

  React.useEffect(() => {
    if (mainSiteOverlay && inputSubmitClicked) {
      const nextStepId = latestInputSubmitNextStepId.current;
      if (nextStepId != null)
        updateNextScriptStepId(nextStepId);

      setInputSubmitNextStepId(null);
      setInputSubmitClicked(false);
    }
  }, [mainSiteOverlay, inputSubmitClicked]);

  // --------------------------------------------------------------      Input short text submit functions
  const handleInputShortTextSubmit = (value: string) => {
    const nextStepId = getNextStepId();
    setInputSubmitNextStepId(nextStepId);

    const inputProps: fromInterfaceOverlay.IFormValueShortTextProps = {
      value,
    };
    handleInputCommonSubmit(inputProps);
  };

  // --------------------------------------------------------------      Input long text submit functions
  const handleInputLongTextSubmit = (value: string) => {
    const nextStepId = getNextStepId();
    setInputSubmitNextStepId(nextStepId);

    const inputProps: fromInterfaceOverlay.IFormValueLongTextProps = {
      value,
    };
    handleInputCommonSubmit(inputProps);
  };

  const getDefaultInputNextStepId = (stepOptions: IScriptStepNextStepOption[]) => {
    const defaultNextStep = stepOptions.filter(stepOption => {
      return stepOption.options[0] === 'default';
    })[0];
    return defaultNextStep.stepId;
  };

  // --------------------------------------------------------------      Input radio buttons change functions
  const handleRadioButtonsChange = (option: string) => {
    const inputProps: fromInterfaceOverlay.IFormValueRadioButtonsProps = {
      value: option,
    };
    const currentStep = getStepById(currentScriptStepId);
    const nextStepOptions: IScriptStepNextStepOption[] = currentStep.nextStepId as IScriptStepNextStepOption[];

    let nextStepId = null;
    nextStepOptions.map(stepOption => {
      if (stepOption.options[0] === option && nextStepId == null)
        nextStepId = stepOption.stepId;
    });

    if (nextStepId == null)
      nextStepId = getDefaultInputNextStepId(nextStepOptions);

    setInputSubmitNextStepId(nextStepId);
    handleInputCommonSubmit(inputProps);
  };

  // --------------------------------------------------------------      Input checkboxes submit functions
  const handleCheckboxesSubmit = (options: string[]) => {
    const inputProps: fromInterfaceOverlay.IFormValueCheckboxesProps = {
      value: options,
    };
    const currentStep = getStepById(currentScriptStepId);
    const nextStepOptions: IScriptStepNextStepOption[] = currentStep.nextStepId as IScriptStepNextStepOption[];

    const submittedOptions = options.sort().join();

    let nextStepId = null;
    nextStepOptions.map(stepOption => {
      const stepOptions = stepOption.options.sort().join();
      if (stepOptions === submittedOptions && nextStepId == null)
        nextStepId = stepOption.stepId;
    });

    if (nextStepId == null)
      nextStepId = getDefaultInputNextStepId(nextStepOptions);

    setInputSubmitNextStepId(nextStepId);
    handleInputCommonSubmit(inputProps);
  };

  // --------------------------------------------------------------      Input common submit functions
  const handleInputCommonSubmit = (inputProps: fromInterfaceOverlay.IFormValueOptions = null) => {
    const inputContent = scriptInputs.filter(input => {
      return input.id === currentUserInputId;
    })[0];

    const newFormValue: fromInterfaceOverlay.IFormValue = {
      id: inputContent.id,
      title: inputContent.title,
      type: inputContent.type,
      props: inputProps,
    };

    if (inputContent.save)
      addFormValue(newFormValue);

    updateCurrentUserInputId(null);
    setInputSubmitClicked(true);
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Show and hide input functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const inputContainerTransitions = useTransition(currentUserInputId, p => p, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: defaultSpringConfig,
  });

  const getUserInput = (inputType: string = '') => {
    if (inputType !== '') {
      const inputContent = scriptInputs.filter(input => {
        return input.id === inputType;
      })[0];

      if (currentUserInputId != null && !userInputVisible)
        updateUserInputVisibility(true);

      if (inputContent.type === "inputShortText") {
        const inputProps: IInputShortTextProps = inputContent.props as IInputShortTextProps;
        return (
          <InputShortText
            id={inputProps.id}
            placeholder={inputProps.placeholder}
            initialValue={''}
            onSubmit={handleInputShortTextSubmit}
            customValidation={inputProps.validation === 'email' ? validateEmail : null}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
      else if (inputContent.type === "inputLongText") {
        const inputProps: IInputLongTextProps = inputContent.props as IInputLongTextProps;
        return (
          <InputLongText
            id={inputProps.id}
            placeholder={inputProps.placeholder}
            initialValue={''}
            onSubmit={handleInputLongTextSubmit}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
      else if (inputContent.type === "radioButtons") {
        const inputProps: IInputRadioButtonsProps = inputContent.props as IInputRadioButtonsProps;
        return (
          <RadioButtons
            id={inputProps.id}
            options={inputProps.options}
            initialOption={null}
            onChange={handleRadioButtonsChange}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
      else if (inputContent.type === "checkboxes") {
        const inputProps: IInputCheckboxesProps = inputContent.props as IInputCheckboxesProps;
        return (
          <Checkboxes
            id={inputProps.id}
            options={inputProps.options}
            initialOption={null}
            onSubmit={handleCheckboxesSubmit}
            triggerStayScrolled={triggerStayScrolled}
            inputVisibility={userInputVisible}
            required
          />
        );
      }
    }

    return null;
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Submit chat functions
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const [triggerChatSubmission, setTriggerChatSubmission] = React.useState(false);

  React.useEffect(() => {
    if (mainSiteOverlay && triggerChatSubmission) {
      setTriggerChatSubmission(false);

      const formDetails = {
        details: formValues,
      };

      api.post('email/contact', formDetails)
        .then(response => {
          response = response.data;
          chatSubmittedSuccessfully(response);
        })
        .catch(error => {
          //
          const response = error.response.data;
          chatSubmitError(response);
        });
      //}
    }
  }, [mainSiteOverlay, triggerChatSubmission]);

  const chatSubmittedSuccessfully = response => {
    triggerNextScriptStep();
  };

  const chatSubmitError = response => {
    updateNextScriptStepId(currentScriptStepId + 'Error');
  };

  // ------------------------------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------      Styles for all modal window elements
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const overlayStyles = {
    opacity: overlaySpring.x.to(x => 0.7 * x),
    display: overlaySpring.x.to(x => x === 0 ? 'none' : 'block'),
  };

  const windowStyles = {
    width: windowWidthSpring.x.to(x => `calc((${x} * (100% - ${triggerWidth}px)) + ${triggerWidth}px)`),
    height: windowHeightSpring.y.to(y => `calc((${y} * (100% - ${triggerWidth}px)) + ${triggerWidth}px)`),
    display: windowWidthSpring.x.to(x => x === 0 ? 'none' : 'block'),
    opacity: windowWidthSpring.x.to(x => open ? (x < 0.3 ? x * (1 / 0.3) : 1) : (x < 0.3 ? x * (1 / 0.3) : 1)),
  };

  const chatContainerStyles = {
    opacity: windowHeightSpring.y.to(y => y),
  };

  const newMessageNotificationStyles = {
    opacity: newMessageSpring.x.to(x => x),
    display: newMessageSpring.x.to(x => x === 0 ? `none` : `block`),
  };

  const triggerStyles = {
    right: windowWidthSpring.x.to(x => `${x * 50}%`),
  };

  const triggerButton = (
    <a.div className='triggerContainer' style={triggerStyles}>
      <ModalTrigger
        mainSiteOverlay={mainSiteOverlay}
        backgroundShade={backgroundShade}
        updateWindowDimensions={updateWindowDimensions}
      />
    </a.div>
  );

  if (mainSiteOverlay)
    return (
      <React.Fragment>
        <a.div
          className={classNames(
            'chatModalOverlay',
            { open },
          )}
          style={overlayStyles}
          onClick={closeChatModal}
        />
        <div className='triggerNotificationSpacer' ref={triggerNotificationElement} />
        <div className='chatModalWindowWrapper' ref={windowWrapper}>
          <a.div
            className={classNames(
              'chatModalWindow',
              { open },
            )}
            style={windowStyles}
          >
            <div className='windowContainer'>
              <a.div className='dragBarHandle' {...dragBarBind()}>
                <a.div className='bar' />
              </a.div>
              <a.div
                className='chatContainer'
                style={chatContainerStyles}
                {...chatContainerBind()}
              >
                <a.div ref={messagesContainerRef} className='messagesContainer' scrollTop={scrollTop} onScroll={onScroll}>
                  <div ref={messagesWrapperRef} className='messagesWrapper spacer'>
                    <div ref={messagesMeasureRef} className='measurementWrapper' />
                    {getMessages()}
                  </div>
                </a.div>
                <div className='userInputContainer' ref={userInputMeasureRef}>
                  {inputContainerTransitions.map(({ item, props: inputProps, key }) => {
                    const commonProps = {
                      key,
                      style: inputProps,
                    };

                    if (item != null)
                      return <a.div {...commonProps}>{getUserInput(item)}</a.div>;
                    else
                      return null;
                  })}
                </div>
                <div className='controlsContainer'>
                  {/*<div className='triggerNewMessage' onClick={handleNewMessageTrigger}>New Message</div>*/}
                  <div className='toContactPage'>
                    <SimpleLink to='/contact' title='Give me other contact options' onClick={closeChatModal}>
                      Give me other contact options.
                    </SimpleLink>
                  </div>
                  <div className='restartChat'>
                    <SimpleLink title='Let me start again' onClick={restartChat}>
                      I messed up! Let me start again.
                    </SimpleLink>
                  </div>
                </div>
                <a.div
                  className='unreadMessagesNotification'
                  style={newMessageNotificationStyles}
                >
                  <div className='text' onClick={scrollBottom}>
                    Unread Messages
                  </div>
                  <div className='closeButton' onClick={removeNewMessageNotification} />
                </a.div>
              </a.div>
            </div>
          </a.div>
          {triggerButton}
        </div>
      </React.Fragment>
    );
  else
    return (
      <div className='chatModalWindowWrapper'>
        {triggerButton}
      </div>
    );
};

// Wire up the React component to the Redux store
export const ModalWindow = withCookies(ModalWindowComponent);
