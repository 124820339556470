import * as React from 'react';
import classNames from 'classnames';

import { a } from 'react-spring';
import { useChatAnimation } from 'hooks/useChatAnimation';

import { usePrevious } from 'utils/Index';
import { useHotkeyHints } from './useHotkeyHints';
import { useOptionsKeys } from './useOptionsKeys';

export interface IProps {
  id: string;
  options: IRadioOption[];
  initialOption: string;
  optionOnEnable?: string; // the option to set when the input is enabled for editing
  optionOnDisable?: string; // the option to set when the input is disabled
  setRef?(ref): void;
  onTouched?(): any;
  setOption?(option: string): any;
  onChange?(option: string): any;
  onEnable?(): any;
  onDisable?(): any;
  onBlur?(): any;
  setError?(error: boolean): any;
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  inputVisibility: boolean;
  triggerStayScrolled: () => void;
}

export interface IRadioOption {
  id: string;
  text: string;
}

export const RadioButtons: React.FC<IProps> = ({
  id,
  options,
  initialOption,
  optionOnEnable,
  optionOnDisable,
  setRef,
  required,
  disabled,
  inputVisibility,
  triggerStayScrolled = () => null,
  ...props
}) => {

  const [
    inputWrapperRef,
    initialised,
    heightSpring,
    inputVisible,
    showInput,
  ] = useChatAnimation(
    triggerStayScrolled,
    inputVisibility,
  );

  const inputWrapperStyles = {
    height: !initialised ? heightSpring.x.to(x => x) : null,
    overflow: 'hidden',
  };

  const [selectedOption, setSelectedOption] = React.useState<string>(null);
  const [touched, setTouched] = React.useState(false);
  const [error, setError] = React.useState(false);

  const previousInitialOption = usePrevious(initialOption);
  const previousDisabled = usePrevious(disabled);

  React.useEffect(() => {
    if (initialOption !== previousInitialOption)
      setSelectedOption(initialOption);

    if (disabled && !previousDisabled)
      onDisable();
    else if (!disabled && previousDisabled)
      onEnable();
  }, [disabled, previousDisabled]);

  const onDisable = () => {
    if (props.onDisable)
      props.onDisable();

    if (optionOnDisable != null)
      setOption(optionOnDisable);
  };

  const onEnable = () => {
    if (props.onEnable)
      props.onEnable();

    if (optionOnEnable != null)
      setOption(optionOnEnable);
    else
      setOption(selectedOption); // this is there to make sure that the option stored in the wrapper component initialises with the same value as this radio button
  };

  const setOption = (optionId: string = null) => {
    setSelectedOption(optionId);
    setError(false);

    if (props.setOption)
      props.setOption(optionId);
  };

  const onChange = (optionId: string) => {
    setSelectedOption(optionId);
    setTouched(true);

    showInput(false);
    if (props.onChange)
      props.onChange(optionId);

    if (props.onTouched)
      props.onTouched();
  };

  const onBlurHandler = () => {
    if (props.onBlur)
      props.onBlur();
  };

  // Hook up keyboard hotkeys to allow users to select options through their keyboard
  const optionsRef = React.useRef<Array<React.MutableRefObject<HTMLInputElement>>>([...Array(options.length)].map(() => React.createRef()));
  useOptionsKeys(options.length, optionsRef);

  // Hook up ? hotkey to show hotkey hints
  const [hotkeySpring] = useHotkeyHints();
  const hotkeyStyles = {
    opacity: hotkeySpring.o,
  };

  const radioOption = (optionId: string, checked: boolean, text: string, index: number) => (
    <div className='optionWrapper' key={optionId}>
      <a.div className='hotkey' style={hotkeyStyles}>{index + 1}</a.div>
      <input
        ref={optionsRef.current[index]}
        type="radio"
        name={id}
        id={optionId}
        value={optionId}
        checked={checked}
        onChange={() => onChange(optionId)}
        onClick={() => onChange(optionId)}
        onBlur={onBlurHandler}
        disabled={disabled}
        className={classNames(
          { error: error || (props.error && touched) },
        )}
      />
      <label htmlFor={optionId}>
        {text}
      </label>
    </div>
  );

  return (
    <a.div
      style={inputWrapperStyles}
    >
      <div
        ref={inputWrapperRef}
        className={classNames(
          'userInputWrapper',
          'radioWrapper',
        )}
      >
        {options.map((option, index) => radioOption(option.id, option.id === selectedOption, option.text, index))}
      </div>
    </a.div>
  );
};
