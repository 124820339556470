import * as React from 'react';

import { ModalWindow } from 'components/InterfaceOverlay/ContactChatModal/ModalWindow';

import * as fromInterfaceOverlay from 'store/InterfaceOverlay';

export type IBackgroundShade = 'light' | 'dark';

interface IProps {
  backgroundShade: IBackgroundShade;
  mainSiteOverlay: boolean;
}

const ChatModal: React.FC<IProps> = ({ ...props }) => {
  //static whyDidYouRender = true;

  return (
    <React.Fragment>
      <ModalWindow {...props} />
    </React.Fragment>
  );
};

// Wire up the React component to the Redux store
export default ChatModal;
